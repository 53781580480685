<template>
  <div class="container-page" v-if="item">
    <p class="title-inner">Карточка данных о мировых ценах</p>

    <div class="row">
      <div class="form-group col-6 required">
        <ComponentInput label="Место торгов/биржа" v-model="item.marketplace" type="text" required />
      </div>
      <div class="form-group col-3 required">
        <ComponentInput label="Год" v-model="item.year" type="number" required />
      </div>
      <div class="form-group col-3 required">
        <ComponentInput
          label="Месяц"
          :select2Options="months"
          v-model="item.month"
          :select2Settings="select2SettingsNoClear"
          type="select2"
          required
        />
      </div>
    </div>

    <div class="row">
      <div class="form-group required col-6">
        <ComponentInput
          label="Параметр"
          :select2Options="parameterList"
          v-model="item.parameterId"
          :select2Settings="select2Settings"
          type="select2"
          required
        />
      </div>
    </div>

    <div class="row">
      <div class="form-group col-6 required">
        <ComponentInput label="Стоимость" v-model="item.cost" type="number" required />
      </div>
      <div class="form-group col-6 required">
        <ComponentInput label="Единица измерения" v-model="item.unit" required />
      </div>
    </div>

    <div class="mb-16">
      <ButtonStock class="mr-8" title="Сохранить" v-on:click="save" />
      <ButtonStock btn-type="secondary" title="Отмена" v-on:click="close" />
    </div>
  </div>
  <section>
    <ModalComponent v-model="catalog.open" :width="950" :title="catalog.title" @close="closeCatalog">
      <CatalogSelector
        v-bind:selected-ids="catalog.selectedIds"
        :route="catalog.route"
        :multiple="false"
        @select="updateCatalogField"
        @close="closeCatalog"
      />
    </ModalComponent>
  </section>
</template>

<script>
  import { useVuelidate } from '@vuelidate/core';

  import Constants from '@/common/constants';
  import Utils from '@/common/utils';

  import ComponentInput from '../../../common/components/ComponentInput';
  import ButtonStock from '../../../components/buttons/ButtonStock';
  import ModalComponent from '../../../components/modals/ModalComponent';
  import CatalogSelector from '../../nsi/components/CatalogSelector';
  import Api from '../api/worldprice.js';

  export default {
    name: 'WorldpriceCard',
    components: { ComponentInput, ButtonStock, ModalComponent, CatalogSelector },
    data() {
      return {
        id: this.$route.params.id,
        item: {
          parameterId: null,
        },
        parameterList: [],
        defaultRequest: {
          pageNumber: 1,
          pageSize: 0,
        },
        select2SettingsNoClear: Constants.select2SettingsNoClear,
        select2Settings: Constants.select2Settings,
        months: Constants.monthList,

        // dialogCancel: false,
        catalog: {
          route: '',
          title: '',
          selectedIds: [],
          open: false,
        },
      };
    },
    created() {
      this.loadDictionaries();
      this.loadCard();
    },
    methods: {
      loadCard() {
        if (this.id > 0) {
          Api.find(this.id).then((response) => {
            this.item = response.data;
            if (this.item.countryId == null) this.item.countryId = true;
          });
        }
      },
      loadDictionaries() {
        Utils.searchNsi('NsiWorldpriceParameter', this.defaultRequest)
          .then((response) => {
            this.parameterList = response.data;
          })
          .catch((error) => {
            console.error(error?.response?.data);
          });
      },
      save() {
        this.v$.$touch();
        if (this.v$.$invalid) {
          Constants.alert.fire('Ошибка', 'Проверьте правильность заполнения полей', 'error');
          return;
        }

        var toSave = Object.assign({}, this.item);
        if (toSave.countryId + '' === 'true') toSave.countryId = null;
        Api.update(this.id, toSave)
          .then(() => {
            Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
            this.$router.push({ name: 'Worldprice' });
          })
          .catch(() => {
            Constants.alert.fire('Сохранение', Constants.commonError, 'error');
          });
      },
      close() {
        this.$router.push({ name: 'Worldprice' });
      },

      // openCatalog(route, title) {
      //   this.catalog.route = route;
      //   this.catalog.title = title;
      //   const selectedIds =
      //     route === 'nsitnved'
      //       ? this.item.tnvedId
      //         ? [this.item.tnvedId]
      //         : []
      //       : this.item.okvedId
      //         ? [this.item.okvedId]
      //         : [];
      //   this.catalog.selectedIds = selectedIds;
      //   this.catalog.open = true;
      // },
      updateCatalogField(selection) {
        this.closeCatalog();
        console.log(selection);
        if (this.catalog.route === 'nsitnved') {
          this.item.tnvedId = selection[0];
        } else {
          this.item.okvedId = selection[0];
        }
      },
      closeCatalog() {
        this.catalog.open = false;
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {};
    },
  };
</script>

<style lang="scss" scoped>
  .catalog-icon {
    right: 20px;
  }
</style>
